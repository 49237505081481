<template>
  <v-container class="px-0" fluid>
    <v-checkbox
      v-model="enableWalkin"
      :label="field.name"
    ></v-checkbox>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  computed: {
    //
  },
  props: ["field", "value"],
  data: () => ({
    enableWalkin: false,
  }),
  watch: {
    enableWalkin: function (val) {
      this.$emit("input", val);
    },
  },
  created() {
    //
  },
  mounted() {
    //
    this.enableWalkin = this.value;
  },
  methods: {
    //
  },
};
</script>