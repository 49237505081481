<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="value"
        :label="field.name"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
  
    <v-time-picker
         v-if="menu2"
      v-model="value"
      full-width
      scrollable
      @click:minute="$refs.menu.save(value)"
        >
        </v-time-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  components: {
    //
  },
  computed: {
    //
  },
  props: ["field", "value"],
  data: () => ({
    time: moment().format("HH:mm"),
    menu2: false,
    modal2: false,
  }),
  watch: {
    time: function (val) {
      this.time = val;
      this.$emit("input", val);
    },
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
   beforeMount(){
    if(!this.value){
      this.value = this.time;
    }
  }
};
</script>