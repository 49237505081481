<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="value"
        :label="field.name"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="value"
      :min="field.min ? field.min : false"
      color="primary"
      @click:date="$refs.menu.save(value)"
      @input="menu2 = false"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  components: {
    //
  },
  computed: {
    //
  },
  props: ["field", "value", "items"],
  data: () => ({
    menu2: false,
    date: moment().format("YYYY-MM-DD"),
    menu: false,
  }),
  watch: {
    date: function (val) {
      this.date = val;
      this.$emit("input", val);
    },
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
  beforeMount() {
    if (!this.value) {
      this.value = this.date;
    } else {
      this.value = moment(this.value).format("YYYY-MM-DD");
    }
  },
};
</script>