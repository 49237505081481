<template>
  <v-select
    v-model="inputVal"
    :label="field.name"
    :items="items"
    :item-text="field.optionText ? field.optionText : 'name'"
    :item-value="field.optionValue ? field.optionValue : 'id'"
    :loading="api.isLoading"
   :rules="rules"
  ></v-select>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },

  computed: {
    ...mapState({
      school: (state) => state.school.data,
    }),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  props: [
    "role",
    "field", 
    "value", 
    "url",
    "rules",
  ],
  data: () => ({
    datas: [],
    //BOC:[api]
    api: {
      isLoading: true,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[select]
    items: [],
    //EOC
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      if (this.role != "Admin" && resp[0].School) {
        this.items = resp.filter((item) => item.School.id === this.school.id);
      } else {
        this.items = resp;
      }
    };
    //EOC
  },
  mounted() {
    //BOC[api]
    this.$api.fetch(this.api);
    //EOC
  },
  methods: {
    //
  },
};
</script>